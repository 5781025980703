import {useSelector} from "react-redux";
import {selectors} from "../../redux/ReportsRedux";
import {TAB_NAME, IReportDetails, IServiceRating} from '../../models/Reports'
import React, {useRef} from "react";
import clsx from "clsx";
import CommentTab from "../SeoStatistic/Tabs/CommentTab";
import AllegroDataStatisticTab from "./Tabs/AllegroDataStatisticTab";
import SatisfactionBlock from '../SatisfactionBlock'

interface IAllegroTabsContent {
    tab: string
}
const AllegroTabsContent: React.FC<IAllegroTabsContent> = ({ tab }) => {
    const reportData = useSelector(selectors.getReportData) as IReportDetails
    const rating = useSelector(selectors.getServicesRatingData) as IServiceRating;
    const componentRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <div className='tab-content border'>
        <div className={clsx('tab-pane', {active: tab === TAB_NAME.COMMENT})}>
          <CommentTab />
        </div>

        {reportData && reportData.details && reportData.details.allegroDaily && (
          <div className={clsx('tab-pane', {active: tab === TAB_NAME.ANALYSIS})}>
            <AllegroDataStatisticTab />
          </div>
        )}
      </div>
      <SatisfactionBlock ratingData={rating} />
    </>
  )
};

export default AllegroTabsContent;
