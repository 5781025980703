import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { IServiceRating } from '../models/Reports';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const SatisfactionBlock: React.FC<{ ratingData?: IServiceRating }> = ({ ratingData }) => {
  useIntl()
  const [currentRating, setCurrentRating] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (ratingData) {
      setCurrentRating(parseInt(ratingData.rating || '0', 10));
    }
  }, [ratingData]);

  if (!ratingData) {
    return null;
  }
  const Alert = withReactContent(Swal);
  const { email, clientId, accessHash } = ratingData;
  const handleRating = (ratingValue: number) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/feedback`, {
        clientId,
        email,
        rating: ratingValue,
        accessHash,
      })
      .then((response) => {
        setCurrentRating(ratingValue);
        Alert.fire({
          icon: 'success',
          title: 'Sukces!',
          text: 'Operacja zakończona pomyślnie!',
          confirmButtonText: 'OK',
          timer: 3000,
          timerProgressBar: true,
          confirmButtonColor: '#5dae4d',
          allowEnterKey: true,
          allowOutsideClick: true,
        });
      })
      .catch((error) => {
        Alert.fire({
          icon: 'error',
          title: 'Błąd!',
          text: 'Coś poszło nie tak. Spróbuj ponownie.',
          confirmButtonText: 'OK',
          timer: 3000,
          timerProgressBar: true,
          confirmButtonColor: '#5dae4d',
          allowEnterKey: true,
          allowOutsideClick: true,
        });
      });
  };

  return (
      <div className='p-5'>
        Oceń nasze usługi:
        <table role="presentation" className={'p-5'} style={{ width: '100%', textAlign: 'left'}}>
          <tbody>
          <tr>
            <td>
              {[1, 2, 3, 4, 5].map((ratingValue) => (
                <button
                  key={ratingValue}
                  onClick={() => handleRating(ratingValue)}
                  style={{
                    border: 'none',
                    background: 'transparent',
                    padding: '0',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    src={`https://via.placeholder.com/30x30?text=${ratingValue}`}
                    alt={`Rating ${ratingValue}`}
                    style={{
                      width: '50px',
                      verticalAlign: 'middle',
                      padding: '0 2px',
                      border: currentRating === ratingValue ? '2px solid blue' : 'none',
                    }}
                  />
                </button>
              ))}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
  );
};

export default SatisfactionBlock;
